<template>
  <div class="wrapper">
    <div class="banner">
      <img src="../../assets/img/enterpriseService/banner.png" alt="" />
    </div>
    <div class="container">
      <div class="box">
        <div class="box_title">工商社保</div>
        <ul class="list">
          <li class="item" @click="toGssbDetail">
            <img src="../../assets/img/enterpriseService/gssb1.png" alt="" />
            <span>公司注册</span>
          </li>
          <li class="item" @click="toGsbg">
            <img src="../../assets/img/enterpriseService/gssb2.png" alt="" />
            <span>公司变更</span>
          </li>
          <li class="item">
            <img src="../../assets/img/enterpriseService/gssb3.png" alt="" />
            <span>公司注销</span>
          </li>
          <li class="item">
            <img src="../../assets/img/enterpriseService/gssb4.png" alt="" />
            <span>银行开户</span>
          </li>
          <li class="item">
            <img src="../../assets/img/enterpriseService/gssb5.png" alt="" />
            <span>地址挂靠</span>
          </li>
          <li class="item">
            <img src="../../assets/img/enterpriseService/gssb6.png" alt="" />
            <span>异常解除</span>
          </li>
          <li class="item" @click="tosbfw">
            <img src="../../assets/img/enterpriseService/gssb7.png" alt="" />
            <span>社保开户</span>
          </li>
          <li class="item" @click="tosbfw">
            <img src="../../assets/img/enterpriseService/gssb8.png" alt="" />
            <span>社保缴纳</span>
          </li>
        </ul>
      </div>
      <div class="box">
        <div class="box_title">知识产权服务</div>
        <ul class="list">
          <li class="item" @click="toKnowledge">
            <img src="../../assets/img/enterpriseService/cqfw1.png" alt="" />
            <span>ISO体系认证</span>
          </li>
          <li class="item" @click="toKnowledge">
            <img src="../../assets/img/enterpriseService/cqfw2.png" alt="" />
            <span>AAA信用评级</span>
          </li>
          <li class="item" @click="toKnowledge">
            <img src="../../assets/img/enterpriseService/cqfw3.png" alt="" />
            <span>高企认定</span>
          </li>
          <li class="item" @click="toKnowledge">
            <img src="../../assets/img/enterpriseService/cqfw4.png" alt="" />
            <span>专精特新</span>
          </li>
          <li class="item" @click="toKnowledge">
            <img src="../../assets/img/enterpriseService/cqfw5.png" alt="" />
            <span>商标申请</span>
          </li>
          <li class="item" @click="toKnowledge">
            <img src="../../assets/img/enterpriseService/cqfw6.png" alt="" />
            <span>专利申请</span>
          </li>
          <li class="item"></li>
          <li class="item"></li>
        </ul>
      </div>
      <div class="box">
        <div class="box_title">科技服务</div>
        <ul class="list">
          <li class="item" @click="toTechnologyDetail">
            <img src="../../assets/img/enterpriseService/gxh1.png" alt="" />
            <span>pc网站搭建</span>
          </li>
          <li class="item" @click="toTechnologyDetail">
            <img src="../../assets/img/enterpriseService/gxh2.png" alt="" />
            <span>公众号建设</span>
          </li>
          <li class="item" @click="toTechnologyDetail">
            <img src="../../assets/img/enterpriseService/gxh3.png" alt="" />
            <span>微网站建设</span>
          </li>
          <li class="item" @click="toTechnologyDetail">
            <img src="../../assets/img/enterpriseService/gxh4.png" alt="" />
            <span>小程序建设</span>
          </li>
          <li class="item" @click="toTechnologyDetail">
            <img src="../../assets/img/enterpriseService/gxh5.png" alt="" />
            <span>APP建设</span>
          </li>
          <li class="item" @click="toTechnologyDetail">
            <img src="../../assets/img/enterpriseService/gxh6.png" alt="" />
            <span>业务系统搭建</span>
          </li>
          <li class="item"></li>
          <li class="item"></li>
        </ul>
      </div>
      <div class="box">
        <div class="box_title">咨询服务</div>
        <ul class="list">
          <li class="item" @click="tozxfw">
            <img src="../../assets/img/enterpriseService/zxfw1.png" alt="" />
            <span>财务管理</span>
          </li>
          <li class="item" @click="tozxfw">
            <img src="../../assets/img/enterpriseService/zxfw2.png" alt="" />
            <span>股权讲堂</span>
          </li>
          <li class="item" @click="tozxfw">
            <img src="../../assets/img/enterpriseService/zxfw3.png" alt="" />
            <span>上市公司管理</span>
          </li>
          <li class="item"></li>
          <li class="item"></li>
          <li class="item"></li>
          <li class="item"></li>
          <li class="item"></li>
        </ul>
      </div>
      <div class="box">
        <div class="box_title">法律服务</div>
        <ul class="list">
          <li class="item" @click="toLegalAdvisor">
            <img src="../../assets/img/enterpriseService/flfw1.png" alt="" />
            <span>法律咨询</span>
          </li>
          <li class="item" @click="toLegalAdvisor">
            <img src="../../assets/img/enterpriseService/flfw2.png" alt="" />
            <span>法律顾问</span>
          </li>
          <li class="item" @click="toLegalAdvisor">
            <img src="../../assets/img/enterpriseService/flfw3.png" alt="" />
            <span>投资合伙</span>
          </li>
          <li class="item" @click="toLegalAdvisor">
            <img src="../../assets/img/enterpriseService/flfw4.png" alt="" />
            <span>企业法律</span>
          </li>
          <li class="item"></li>
          <li class="item"></li>
          <li class="item"></li>
          <li class="item"></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    toGsbg() {
      this.$router.push({
        path: "/industry",
      });
    },
    toGssbDetail() {
      this.$router.push({
        path: "/home/gssb",
        query: { org: "enterpriseService" },
      });
    },
    toTechnologyDetail() {
      this.$router.push({
        path: "/scienceService",
      });
    },
    toKnowledge() {
      this.$router.push({
        path: "/learningService",
      });
    },
    // 法律服务
    toLegalAdvisor() {
      this.$router.push({
        path: "/lawService",
      });
    },

    // toLegalDetail() {
    //   this.$router.push({
    //     path: "/industry",
    //   });
    // },
    // 咨询服务
    tozxfw() {
      this.$router.push({
        path: "/series",
      });
    },
    // 社保服务
    tosbfw() {
      this.$router.push({
        path: "/service_solution",
      });
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
.banner {
  width: 100%;
  height: 300px;
}

.banner img {
  display: block;
  width: 100%;
  height: 100%;
}

.container {
  padding: 60px;
}

.box {
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 60px;
}

.box_title {
  font-size: 20px;
  font-weight: 600;
}

.title {
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.list {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.item {
  width: 132px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item img {
  display: block;
  width: 132px;
  height: 132px;
}

.item span {
  display: block;
  font-size: 14px;
  color: #333;
  margin-top: 10px;
}
</style>
